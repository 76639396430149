import React, { useState } from 'react';

const Terminal = () => {
    const [command, setCommand] = useState('');
    const [output, setOutput] = useState([]);

    const commands = {
        help: `Available commands: experience, education, skills, contact, clear`,
        experience: `Experience:<br/>
- I am French and have extensive international experience as a system and network administrator since 2013.<br/>
- I work in particular in France or Vietnam. I am also a Vietnamese Culinary Chef as a passion that you can discover on my Instagram.<br/>
- You can download my complete CV from here: <a href="https://kdrive.infomaniak.com/app/share/534311/93bb39e3-646d-40cf-9689-64701eb12c59" target="_blank" rel="noopener noreferrer">Download CV</a><br/>
- Administrator System & Network - MODELABS - Paris (2024-now)<br/>
- HelpDesk N1/N2 - RXGLOBAL - Puteaux (2019-2023)<br/>
- Engineer System Administrator - SERDAO - HCMC (2018-2019)<br/>
- System Administrator - AAMSET - Paris (2017-2018)<br/>
- Technical Sales - IKOULA Paris (2016)<br/>
- Technician Support IT DataCenter - IKOULA - Reims (2016)<br/>
- System Administrator - PHSDIGITAL - Hanoi (2014-2015)<br/>
- Technician Support IT - VIBC - Hanoi (2013-2014)<br/>
- Web Developer - Sviet-Travel - Hanoi (2013)<br/>
- System Administrator Freelance - IGESTION Paris - France (2013)<br/>
- System Administrator - Freelance Paris – France (2013)<br/>
- Technician IT - Stage Informati'K Discount Charleville-Mezieres - France (2010)`,
        education: `Education:<br/>
- Ubiquiti Enterprise Wireless Admin - UBIQUITI Paris (2021)<br/>
- Certificate Meraki Networking Associate - Cisco Meraki Vietnam (2019)<br/>
- IT Technician Certificate - EDUCATEL Paris - France (2009-2011)<br/>
- Certification Gnu/Linux lvl1 - Professional Linux Institut Paris - France (2013 - 2014)`,
        skills: `Skills:<br/>
- Programming Languages: PowerShell, Bash, Python<br/>
- Databases: MySQL, PostgreSQL, MSSQL<br/>
- OS: Windows Desktop-Server, Linux Debian/Ubuntu, MACOS`,
        contact: `Contact:<br/>
- Email: contact@husson-aurelien.tech<br/>
- Phone: +33 07 86 52 63 27<br/>
- LinkedIn: <a href="https://www.linkedin.com/in/aurelienson/" target="_blank" rel="noopener noreferrer">LinkedIn Profile</a><br/>
- GitHub: <a href="https://github.com/aurelson101" target="_blank" rel="noopener noreferrer">GitHub Profile</a>`,
    };

    const handleCommand = (e) => {
        if (e.key === 'Enter') {
            if (command === 'clear') {
                setOutput([]);
            } else if (commands[command]) {
                setOutput([...output, `> ${command}`, <div key={output.length} dangerouslySetInnerHTML={{ __html: commands[command] }} />]);
            } else {
                setOutput([...output, `> ${command}`, `'${command}' is not recognized as a command. Type 'help' to see available commands.`]);
            }
            setCommand('');  // Reset the command input
        }
    };

    return (
        <div className="terminal">
            <div className="terminal-header">
                <div className="buttons">
                    <div className="button close"></div>
                    <div className="button minimize"></div>
                    <div className="button maximize"></div>
                </div>
                <div className="title">Terminal - type Help to display available commands</div>
            </div>
            <div className="terminal-body">
                {output.map((item, index) => (
                    <div key={index}>{item}</div>
                ))}
                <div>
                    <span className="prompt">> </span>
                    <input
                        type="text"
                        value={command}
                        onChange={(e) => setCommand(e.target.value)}
                        onKeyPress={handleCommand}
                        autoFocus
                    />
                </div>
            </div>
        </div>
    );
};

export default Terminal;